import React, { useState, useEffect, useContext } from "react";
import { Table } from "antd";
import {
  getRequests,
  getFlat,
  getCheckIn,
  postRefusal,
} from "../../Endpoints/Endpoints";
import { Button } from "antd";
import { Link } from "react-router-dom";
import accountContext from "../../Context";

export default function ReportingsList() {
  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [token] = useContext(accountContext);
  const onRefusal = async (key) => {
    await postRefusal(key, token);
    await loadData();
  };

  const columns = [
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "",
      key: "reject",
      render: (text, record) => (
        <Button danger type="primary" onClick={() => onRefusal(record.key)}>
          Reject
        </Button>
      ),
    },
    {
      title: "",
      key: "assign",
      render: (text, record) => (
        <Link to={`/assign-reporting/${record.key}`}>
          <Button type="primary">Assign</Button>
        </Link>
      ),
    },
  ];

  const loadData = async () => {
    const requests = (await getRequests(token)).filter(
      (request) => request.status === "PENDING"
    );
    const requestsInfo = Promise.all(
      requests.map(async ({ id, checkInId, type, description }) => {
        const { flatId } = await getCheckIn(checkInId, token);
        const { building, number } = await getFlat(flatId, token);
        const { city, street, streetNumber } = building;

        const dataToShow = {
          key: id,
          address: `${city} ${street} ${streetNumber}/${number}`,
          type,
          description,
        };
        return dataToShow;
      })
    );
    setIsLoading(true);
    setRequests(await requestsInfo);
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);
  return <Table dataSource={requests} columns={columns} loading={isLoading} />;
}
