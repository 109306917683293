import React, { useContext } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import accountContext from "../Context";

export default function Navbar() {
  const [, , role] = useContext(accountContext);

  return (
    <Menu
      theme="dark"
      mode="horizontal"
      defaultSelectedKeys={["1"]}
      selectedKeys={[role === "none" ? "2" : "1"]}
    >
      {role !== "none" && (
        <Menu.Item key="1">
          <Link to={role == "DWELLER" ? "/dweller-panel" : "/admin-panel"}>
            Panel
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="2">
        <Link to="/login">{role === "none" ? "Login" : "Logout"}</Link>
      </Menu.Item>
    </Menu>
  );
}
