import React, { useEffect, useState, useContext } from "react";
import { Table } from "antd";
import { dataSource } from "./CheckInListData";
import {
  getCheckIns,
  getDweller,
  getFlat,
  putCheckOut,
} from "../../Endpoints/Endpoints";
import { Button } from "antd";
import accountContext from "../../Context";
export default function CheckInList() {
  const [checkIns, setCheckIns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [token] = useContext(accountContext);

  const onCheckout = async (key) => {
    await putCheckOut(key, token);
    await loadData();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Check-in date",
      dataIndex: "checkInDate",
      key: "checkInDate",
    },
    {
      title: "Date of birth",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
    },
    {
      title: "",
      key: "checkOutButton",
      render: (text, record) => (
        <Button type="primary" onClick={() => onCheckout(record.key)}>
          Check out
        </Button>
      ),
    },
  ];

  const loadData = async () => {
    const checkIns = (await getCheckIns(token)).filter(
      (checkIn) => checkIn.endedAt === null
    );
    const checkInsInfo = Promise.all(
      checkIns.map(async ({ id, begunAt, dwellerId, flatId }) => {
        const { building, number } = await getFlat(flatId, token);
        const { city, street, streetNumber } = building;
        const { firstName, lastName, dateOfBirth } = await getDweller(
          dwellerId,
          token
        );
        const dataToShow = {
          key: id,
          address: `${city} ${street} ${streetNumber}/${number}`,
          firstName,
          lastName,
          dateOfBirth: dateOfBirth.substring(0, 10),
          checkInDate: begunAt.substring(0, 10),
        };
        return dataToShow;
      })
    );
    setIsLoading(true);
    setCheckIns(await checkInsInfo);
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);
  return <Table dataSource={checkIns} columns={columns} loading={isLoading} />;
}
