import React from "react";
import { Button } from "antd";
import "./General.css";
import { Link } from "react-router-dom";

export default function CreateReports() {
  return (
    <div className="button-layout">
      <Button type="primary" size="large" className="button">
        <a href="https://tab-api.modie.pl/api/payment/late">
          Create report of late payments
        </a>
      </Button>
      <Button type="primary" size="large" className="button">
        <a href="https://tab-api.modie.pl/api/dweller-request/in-progress">
          Create profit report
        </a>
      </Button>
      <Button type="primary" size="large" className="button">
        <a href="https://tab-api.modie.pl/api/payment/income?dateFrom=2020-12-11&dateTo=2021-12-12">
          Create report of current repairs
        </a>
      </Button>
    </div>
  );
}
