import React, { useState } from "react";
import { Layout } from "antd";
import Navbar from "../Components/Navbar";
import Routing from "../Routing/Routing";
import AccountContext from "../Context";

const { Content, Header } = Layout;

export default function PageLayout() {
  const [token, setToken] = useState("");
  const [role, setRole] = useState("none");
  return (
    <AccountContext.Provider value={[token, setToken, role, setRole]}>
      <Layout className="layout">
        <Header>
          <Navbar />
        </Header>
        <Content style={{ textAlign: "center", padding: "20px" }}>
          <Routing />
        </Content>
      </Layout>
    </AccountContext.Provider>
  );
}
