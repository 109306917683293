import { Formik } from "formik";
import { Form, Select, Input, SubmitButton } from "formik-antd";
import { Spin, notification } from "antd";
import React, { useContext, useState, useEffect } from "react";
import accountContext from "../Context";
import {
  getContractors,
  postContractorAssigment,
} from "../Endpoints/Endpoints";
import { useParams } from "react-router";
const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 6,
  },
};

export default function AssignReporting() {
  const [contractors, setContractors] = useState([]);
  const { assigmentId } = useParams();
  const [token] = useContext(accountContext);
  useEffect(() => {
    loadContractors();
  }, []);

  const loadContractors = async () => {
    const contractorsData = (await getContractors(token)).data;
    const mappedConstractors = contractorsData.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    setContractors(mappedConstractors);
  };

  return (
    <Formik
      initialValues={{ name: "" }}
      onSubmit={async ({ contractorId, price }, { setSubmitting }) => {
        try {
          await postContractorAssigment(
            assigmentId,
            contractorId,
            price,
            token
          );
          notification["success"]({
            message: "Reporting assigned!",
            description: `You've succesfully assigned reporting!`,
          });
        } catch {
          notification["error"]({
            message: "Reporting not assigned!",
            description: `Something went wrong!`,
          });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      <Form {...layout}>
        <Form.Item
          label="Company name"
          name="contractorId"
          rules={[
            {
              required: true,
              message: "Please select name!",
            },
          ]}
        >
          <Select
            name="contractorId"
            style={{ width: 400 }}
            options={contractors}
          />
        </Form.Item>
        <Form.Item
          label="Price"
          name="price"
          rules={[
            {
              required: true,
              message: "Please input price!",
            },
          ]}
        >
          <Input name="price" type="number" />
        </Form.Item>
        <SubmitButton>Assign</SubmitButton>
      </Form>
    </Formik>
  );
}
