import { Formik } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import React, { useContext, useEffect } from "react";
import { getCurrentAccount, postLogin } from "../../Endpoints/Endpoints";
import accountContext from "../../Context";
import { useHistory } from "react-router";
import { notification } from "antd";
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 6,
  },
};

export default function Login() {
  const [, setToken, , setRole] = useContext(accountContext);
  const history = useHistory();

  useEffect(() => {
    setToken("");
    setRole("none");
  }, []);

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={async ({ email, password }, { setSubmitting }) => {
        try {
          const response = await postLogin(email, password);
          setToken(response.data.token);
          const account = await getCurrentAccount(response.data.token);
          const { dwellerId } = account.data;
          const role = dwellerId === undefined ? "MANAGER" : "DWELLER";
          setRole(role);
          if (role == "MANAGER") history.push("/admin-panel");
          else if (role == "DWELLER") history.push("/dweller-panel");
        } catch {
          setSubmitting(false);
          notification["error"]({
            message: "Can't login!",
            description: `Check your email and password!`,
          });
        }
      }}
    >
      <Form {...layout}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter email!",
            },
          ]}
        >
          <Input name="email" placeholder="Login" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter password!",
            },
          ]}
        >
          <Input.Password name="password" placeholder="Password" />
        </Form.Item>
        <SubmitButton>Login</SubmitButton>
      </Form>
    </Formik>
  );
}
