export const columns = [
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Expected payment date",
    dataIndex: "expectedPaymentDate",
    key: "expectedPaymentDate",
  },
  {
    title: "Cost",
    dataIndex: "cost",
    key: "cost",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];
