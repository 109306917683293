import { Formik } from "formik";
import { Form, Input, Select, SubmitButton } from "formik-antd";
import React, { useContext, useEffect, useState } from "react";
import accountContext from "../Context";
import {
  getDwellers,
  getFlats,
  postCheckIn,
  registerDwellerAccount,
} from "../Endpoints/Endpoints";
import { notification } from "antd";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 6,
  },
};

export default function RegisterDwellerAccount() {
  const [token] = useContext(accountContext);
  const [dwellers, setDwellers] = useState();
  const [flats, setFlats] = useState();

  useEffect(() => {
    loadDwellers();
    loadFlats();
  }, []);

  const loadDwellers = async () => {
    const dwellersData = await getDwellers(token);
    const mappedDwellers = dwellersData.map(
      ({ id, firstName, lastName, dateOfBirth }) => ({
        value: id,
        label: `${firstName} ${lastName}, ${dateOfBirth.substring(0, 10)}`,
      })
    );
    setDwellers(mappedDwellers);
  };

  const loadFlats = async () => {
    const flats = await getFlats(token);
    const notTakenFlats = flats.filter(
      (flat) => !flat.checkIns.some((checkIn) => checkIn.endedAt === null)
    );
    const mappedFlats = notTakenFlats.map(({ id, building, number }) => ({
      value: id,
      label: `${building.city}, ${building.street} ${building.streetNumber}/${number}`,
    }));
    setFlats(mappedFlats);
  };

  return (
    <Formik
      initialValues={{ dwellerId: undefined, flatId: undefined }}
      onSubmit={async ({ dwellerId, email, password }, { setSubmitting }) => {
        try {
          await registerDwellerAccount(dwellerId, email, password, token);
          notification["success"]({
            message: "Dweller account created!",
            description: `You've succesfully created account for a dweller!`,
          });
        } catch {
          notification["error"]({
            message: "Something went wrong!",
            description: `Check your email or if dweller already has an account!`,
          });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      <Form {...layout}>
        <Form.Item
          label="Dweller"
          name="dwellerId"
          rules={[
            {
              required: true,
              message: "Please select a dweller you want to check in!",
            },
          ]}
        >
          <Select name="dwellerId" options={dwellers} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input email!",
            },
          ]}
        >
          <Input name="email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input password!",
            },
          ]}
        >
          <Input name="password" type="password" />
        </Form.Item>
        <SubmitButton>Create account</SubmitButton>
      </Form>
    </Formik>
  );
}
