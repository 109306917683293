import { Formik } from "formik";
import { Form, Input, Select, SubmitButton, DatePicker } from "formik-antd";
import React, { useContext, useEffect, useState } from "react";
import accountContext from "../Context";
import { postDweller } from "../Endpoints/Endpoints";
import { notification } from "antd";
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 6,
  },
};

export default function RegisterDweller() {
  const [token] = useContext(accountContext);

  return (
    <Formik
      initialValues={{
        firstName: undefined,
        lastName: undefined,
        dateOfBirth: new Date(),
      }}
      onSubmit={async (
        { firstName, lastName, dateOfBirth },
        { setSubmitting }
      ) => {
        try {
          await postDweller(firstName, lastName, dateOfBirth, token);
          notification["success"]({
            message: "Dweller added!",
            description: `You've succesfully added a dweller!`,
          });
        } catch {
          notification["error"]({
            message: "Dweller not added!",
            description: `Something went wrong!`,
          });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      <Form {...layout}>
        <Form.Item
          label="First name"
          name="firstName"
          rules={[
            {
              required: true,
              message: "Please enter a first name!",
            },
          ]}
        >
          <Input name="firstName" />
        </Form.Item>
        <Form.Item
          label="Last name"
          name="lastName"
          rules={[{ required: true, message: "Please enter a last name!" }]}
        >
          <Input name="lastName" />
        </Form.Item>
        <Form.Item
          label="Date of birth"
          name="dateOfBirth"
          rules={[{ required: true, message: "Please enter a date of birth!" }]}
        >
          <DatePicker name="dateOfBirth" />
        </Form.Item>
        <SubmitButton>Register</SubmitButton>
      </Form>
    </Formik>
  );
}
