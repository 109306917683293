import React from "react";
import { Route } from "react-router-dom";
import AdminPanel from "../Pages/AdminPanel";
import CheckInList from "../Pages/CheckInList/CheckInList";
import CreateReports from "../Pages/CreateReports";
import RegisterDwellerArrival from "../Pages/CheckInDweller";
import PaymentsList from "../Pages/RegisterPayment/PaymentsList";
import ReportingsList from "../Pages/ReportingsList/ReportingsList";
import AssignReporting from "../Pages/AssignReporting";
import DwellerPanel from "../Pages/DwellerPanel";
import DwellerPaymentsList from "../Pages/BrowsePayment/DwellerPaymentsList";
import ReportFault from "../Pages/ReportFault";
import Login from "../Pages/LoginPage";
import RegisterDweller from "../Pages/RegisterDweller";
import RegisterDwellerAccount from "../Pages/RegisterDwellerAccount";

export default function Routing() {
  return (
    <>
      <Route path="/admin-panel">
        <AdminPanel />
      </Route>
      <Route path="/create-reports">
        <CreateReports />
      </Route>
      <Route exact path="/register-dweller-arrival">
        <RegisterDwellerArrival />
      </Route>
      <Route exact path="/check-in-list">
        <CheckInList />
      </Route>
      <Route exact path="/defects-reportings">
        <ReportingsList />
      </Route>
      <Route exact path="/register-payment">
        <PaymentsList />
      </Route>
      <Route exact path="/assign-reporting/:assigmentId">
        <AssignReporting />
      </Route>
      <Route exact path="/dweller-panel">
        <DwellerPanel />
      </Route>
      <Route exact path="/browse-payments">
        <DwellerPaymentsList />
      </Route>
      <Route path="/report-fault">
        <ReportFault />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/register-dweller">
        <RegisterDweller />
      </Route>
      <Route exact path="/register-dweller-account">
        <RegisterDwellerAccount />
      </Route>
      <Route exact path="/">
        <Login />
      </Route>
    </>
  );
}
