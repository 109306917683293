import React from "react";
import { Button } from "antd";
import "./General.css";
import { Link } from "react-router-dom";

export default function DwellerPanel() {
  return (
    <div className="button-layout">
      <Button type="primary" size="large" className="button">
        <Link to="/browse-payments">Browse your payments</Link>
      </Button>
      <Button type="primary" size="large" className="button">
        <Link to="/report-fault">Report a fault</Link>
      </Button>
    </div>
  );
}
