import React, { useEffect, useState, useContext } from "react";
import { Table } from "antd";
import {
  getFlat,
  getPayments,
  putConfirmation,
} from "../../Endpoints/Endpoints";
import AccountContext from "../../Context";
import { Button } from "antd";

export default function PaymentsList() {
  const [payments, setPayments] = useState([]);
  const [token] = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Expected payment date",
      dataIndex: "expectedPaymentDate",
      key: "expectedPaymentDate",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
    },
    {
      title: "",
      key: "register",
      render: (text, record) => (
        <Button type="primary" onClick={() => onRegister(record.key)}>
          Register
        </Button>
      ),
    },
  ];

  const onRegister = async (id) => {
    await putConfirmation(id, token);
    loadPayments();
  };

  useEffect(() => {
    loadPayments();
  }, []);

  const loadPayments = async () => {
    setIsLoading(true);
    const paymentsData = await getPayments(token);
    let paymentInfo = await Promise.all(
      paymentsData.map(
        async ({ flatId, id, expectedPaymentDate, amount, status }) => {
          const { building, number } = await getFlat(flatId, token);
          const { city, street, streetNumber } = building;
          const dataToShow = {
            key: id,
            address: `${city} ${street} ${streetNumber}/${number}`,
            expectedPaymentDate: expectedPaymentDate.substring(0, 10),
            cost: amount,
            status,
          };
          return dataToShow;
        }
      )
    );
    paymentInfo = paymentInfo.filter(({ status }) => status == "PENDING");
    setPayments(paymentInfo);
    setIsLoading(false);
  };
  return <Table dataSource={payments} columns={columns} loading={isLoading} />;
}
