import React from "react";
import { Button } from "antd";
import "./General.css";
import { Link } from "react-router-dom";

export default function AdminPanel() {
  return (
    <div className="button-layout">
      <Button type="primary" size="large" className="button">
        <Link to="/create-reports">Create reports</Link>
      </Button>
      <Button type="primary" size="large" className="button">
        <Link to="/register-dweller-arrival">Check-in dweller </Link>
      </Button>
      <Button type="primary" size="large" className="button">
        <Link to="/check-in-list">Check-in list </Link>
      </Button>
      <Button type="primary" size="large" className="button">
        <Link to="/defects-reportings">Defects reportings </Link>
      </Button>
      <Button type="primary" size="large" className="button">
        <Link to="/register-payment">Register payment </Link>
      </Button>
      <Button type="primary" size="large" className="button">
        <Link to="/register-dweller">Register dweller </Link>
      </Button>
      <Button type="primary" size="large" className="button">
        <Link to="/register-dweller-account">Register dweller account</Link>
      </Button>
    </div>
  );
}
