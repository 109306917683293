import axios from "axios";

const dwellerToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Nywicm9sZSI6IkRXRUxMRVIiLCJleHAiOjE2MjM2MDA0NzEuOTEyLCJpYXQiOjE2MjI5OTU2NzF9.drNDYYIcW4cNl23uvHTGBrruw8o4WEvxDP7RK66yeR4";

const adminToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Mywicm9sZSI6Ik1BTkFHRVIiLCJleHAiOjE2MjM2MDg1MjAuNDMyLCJpYXQiOjE2MjMwMDM3MjB9.UrHFinOKpXimADMx6oCP5A_V-oTuaKWSSlkKDOpD6FI";

const tmpToken = dwellerToken;

export const putConfirmation = async (id, token) =>
  await axios.put(
    `https://tab-api.modie.pl/api/payment/${id}/confirmation`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const postCheckIn = async (dwellerId, flatId, token) =>
  await axios.post(
    `https://tab-api.modie.pl/api/flat/${flatId}/checkin`,
    {
      dwellerId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const postLogin = async (email, password) =>
  await axios.post("https://tab-api.modie.pl/api/account/login", {
    email,
    password,
  });

export const postContractorAssigment = async (
  assigmentId,
  contractorId,
  price,
  token
) =>
  await axios.post(
    `https://tab-api.modie.pl/api/dweller-request/${assigmentId}/contractor`,
    {
      contractorId,
      cost: price,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const registerDwellerAccount = async (
  dwellerId,
  email,
  password,
  token
) =>
  await axios.post(
    `https://tab-api.modie.pl/api/account/register`,
    {
      dwellerId,
      email,
      password,
      userRole: "DWELLER",
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getCurrentAccount = async (token) =>
  await axios.get("https://tab-api.modie.pl/api/account/me", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getContractors = async (token) =>
  await axios.get("https://tab-api.modie.pl/api/contractor", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getPayments = async (token = dwellerToken) => {
  const response = await axios.get("https://tab-api.modie.pl/api/payment", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getFlats = async (token) => {
  const response = await axios.get(`https://tab-api.modie.pl/api/flat`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getFlat = async (id, token) => {
  const response = await axios.get(`https://tab-api.modie.pl/api/flat/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getCheckIn = async (id, token) => {
  const response = await axios.get(
    `https://tab-api.modie.pl/api/checkin/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const getCheckIns = async (token) => {
  const response = await axios.get("https://tab-api.modie.pl/api/checkin", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDwellers = async (token) => {
  const response = await axios.get(`https://tab-api.modie.pl/api/dweller/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getDweller = async (id, token) => {
  const response = await axios.get(
    `https://tab-api.modie.pl/api/dweller/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const postDweller = async (firstName, lastName, dateOfBirth, token) =>
  await axios.post(
    `https://tab-api.modie.pl/api/dweller`,
    { firstName, lastName, dateOfBirth },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const putCheckOut = async (id, token) =>
  await axios.put(
    `https://tab-api.modie.pl/api/checkin/${id}/checkout`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const postRefusal = async (id, token) =>
  await axios.post(
    `https://tab-api.modie.pl/api/dweller-request/${id}/refusal`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getRequests = async (token) => {
  const response = await axios.get(
    `https://tab-api.modie.pl/api/dweller-request`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const postFault = async (flatId, type, description, token) =>
  await axios.post(
    `https://tab-api.modie.pl/api/dweller-request`,
    {
      flatId,
      type,
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
