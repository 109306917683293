import { Formik } from "formik";
import { Form, Select, SubmitButton, Field, Input } from "formik-antd";
import React, { useState, useEffect, useContext } from "react";
import accountContext from "../Context";
import { getFlats, postFault } from "../Endpoints/Endpoints";

const { Option } = Select;
//const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 6,
  },
};
export default function ReportFault() {
  const [flats, setFlats] = useState([]);
  const [token] = useContext(accountContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const response = async () => {
      setIsLoading(true);
      const flats = await getFlats(token);
      const mappedFlats = flats.map(({ id, building, number }) => ({
        value: id,
        label: `${building.city}, ${building.street} ${building.streetNumber}/${number}`,
      }));
      setFlats(mappedFlats);
      setIsLoading(false);
    };
    response();
  }, []);

  return (
    <Formik
      initialValues={{ address: "", description: "", type: "RENOVATION" }}
      onSubmit={async ({ address, type, description }, { setSubmitting }) => {
        try {
          await postFault(address, type, description, token);
        } catch {
          setSubmitting(false);
        } finally {
          setSubmitting(false);
        }
      }}
      render={() => (
        <Form {...layout}>
          <Form.Item
            label="Address"
            name="address"
            rules={[{ required: true, message: "Please input your address!" }]}
          >
            <Select name="address" options={flats} />
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            rules={[{ required: true, message: "Please input type of fault!" }]}
          >
            <Select
              name="type"
              options={[
                { value: "RENOVATION", label: "RENOVATION" },
                { value: "REPAIR", label: "REPAIR" },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              { required: true, message: "Please input description of fault!" },
            ]}
          >
            <Input.TextArea name="description" />
          </Form.Item>
          <SubmitButton>Report</SubmitButton>
        </Form>
      )}
    />
  );
}
