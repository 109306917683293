import React, { useContext, useEffect, useState } from "react";
import { Table } from "antd";
import { columns, dataSource } from "./DwellerPaymentsListData";
import { getPayments, getFlat } from "../../Endpoints/Endpoints";
import accountContext from "../../Context";

export default function DwellerPaymentsList() {
  const [payments, setPayments] = useState([]);
  const [token] = useContext(accountContext);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const response = async () => {
      const paymentsData = await getPayments(token);
      const paymentInfo = Promise.all(
        paymentsData.map(
          async ({ flatId, id, expectedPaymentDate, amount, status }) => {
            const { building, number } = await getFlat(flatId, token);
            const { city, street, streetNumber } = building;
            const dataToShow = {
              key: id,
              address: `${city} ${street} ${streetNumber}/${number}`,
              expectedPaymentDate: expectedPaymentDate.substring(0, 10),
              cost: amount,
              status,
            };
            return dataToShow;
          }
        )
      );
      setIsLoading(true);
      setPayments(await paymentInfo);
      setIsLoading(false);
    };
    response();
  }, []);
  return <Table dataSource={payments} columns={columns} loading={isLoading} />;
}
